import { combineReducers } from "redux";
import userReducer from "./User"
import AlertPopupReducer from "./AlertPopup";
import companyReducer from "./Company";
import topBarMsgReducer from "./TopbarMsg";

const Reducers = combineReducers({
  CurrentUser: userReducer,
  AlertPopup: AlertPopupReducer,
  companySettings: companyReducer,
  topBarMsg: topBarMsgReducer,

});

export default Reducers;
