
import { AES, enc } from 'crypto-js';


const secretKey = process.env.REACT_APP_SECRET_KEY;

export function encryptData(data) {
    const encryptedData = AES.encrypt(data, secretKey).toString();
    return encryptedData;
}
export function decryptData(edata) {
    const decryptedData = AES.decrypt(edata, secretKey).toString(enc.Utf8);
    return decryptedData;
}