import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "user",
    initialState: {
        currentUser: [],
        loggedIn: false,
        loading: false,
        lastFetch: null,
        openLogin: false,
        role: null,
        memberId: null,
        loginExpiresIn: null,

    },
    reducers: {
        userInfoReveived: (user, action) => {
            user.loading = false;
            user.loggedIn = true;
            user.currentUser = action.payload;
            user.memberId = action.payload.id;
            user.role = action.payload.role;
            user.loginExpiresIn = action.payload.login_expires_in
        },
        userLogOut: (user, action) => {
            user.loggedIn = false;
            user.loading = false;
            user.role = null;
            user.memberId = null;
            user.loginExpiresIn = null;
            user.currentUser = [];
        },

    },
});

export const {
    userInfoReveived,
    userLogOut,
} = slice.actions;
export default slice.reducer;
