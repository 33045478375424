import React, { Children, useEffect, useState } from "react";
import * as BS from "react-icons/bs";
import DynamicIcon from "../icons";

import { CSSTransition } from "react-transition-group";

export default function DropdownMobileMenu({
  bgColor = "transparent",
  dropdownLbl,
  children,
  titleTextColor = "black",
  iconName,
}) {
  const [showSubMenu, setShowSubMenu] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => setShowSubMenu(!showSubMenu)}
        onClick={() => setShowSubMenu(!showSubMenu)}
        onMouseLeave={() => setShowSubMenu(false)}
      >
        <div className="cursor-pointer flex items-center text-white">
          <div className="flex justify-between items-center ml-1">
            <div className="mr-3">
              <DynamicIcon name={iconName} classname="text-white" />
            </div>
            <div className=" mr-3">{dropdownLbl}</div>
            <div className="text-white">
              <BS.BsChevronDown className={showSubMenu ? "rotate-180" : ""} />
            </div>
          </div>
        </div>
        {showSubMenu && (
          <div className="bg-[#a30508] pl-5 " style={{ zIndex: 1001 }}>
            {children}
          </div>
        )}
      </div>
    </>
  );
}
