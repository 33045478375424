import React, { Children, useEffect, useState } from "react";
import * as BS from "react-icons/bs";

import { CSSTransition } from "react-transition-group";

export default function DropdownMenu({
  bgColor = "transparent",
  dropdownLbl,
  children,
  titleTextColor = "black",
}) {
  const [showSubMenu, setShowSubMenu] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => setShowSubMenu(!showSubMenu)}
        onClick={() => setShowSubMenu(!showSubMenu)}
        onMouseLeave={() => setShowSubMenu(false)}
      >
        <div className="cursor-pointer flex items-center  h-[50px]">
          <div className="flex justify-between items-center ml-1 mr-3">
            <div className="">{dropdownLbl}</div>
            <div className="ml-2">
              <BS.BsChevronDown className={showSubMenu ? "rotate-180" : ""} />
            </div>
          </div>
        </div>
        {showSubMenu && (
          <div
            className="absolute bg-[#a30508] w-[250px]"
            style={{ zIndex: 1001 }}
          >
            {children}
          </div>
        )}
      </div>
    </>
  );
}
