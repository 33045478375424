import { createSlice } from "@reduxjs/toolkit";
import { BsChevronDoubleLeft } from "react-icons/bs";

const slice = createSlice({
  name: "AlertPopup",
  initialState: {
    alertMessage: "",
    showAlert: false,
    alertTitle: "",
    alertType: "success",
  },

  reducers: {
    showAlertPopup: (state, action) => {
      state.showAlert = true;
      state.alertType = action.payload.alertType;
      state.alertTitle = action.payload.alertTitle;
      state.alertMessage = action.payload.alertMessage;
    },
    hideAlertPopup: (state) => {
      state.showAlert = !state.showAlert;
      state.alertType = "";
      state.alertTitle = "";
      state.alertMessage = "";
      state.message = "";
    },
  },
});

export const { showAlertPopup, hideAlertPopup } = slice.actions;

export default slice.reducer;
