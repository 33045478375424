import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col bg-orange-600 mt-5 rounded-md justify-center items-center w-[100%]">
      <div className="flex bg-orange-600 mt-5 p-2  rounded-md justify-center items-center gap-3 w-[100%]">
        <div className="flex justify-center items-center w-[100px] h-[100px]  md:w-[120px] md:h-[120px] rounded-full bg-yellow-500 text-black shadow-md">
          <h1>404</h1>
        </div>
        <h1 className="text-black">Requested URL was not found.</h1>
      </div>
      <div className="flex items-end justify-end w-[100%] px-5 pb-3">
        <button
          className="flex px-3 py-2 rounded-md justify-center items-center gap-2 bg-orange-300 text-orange-600"
          onClick={() => navigate("/home")}
        >
          Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
