import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { RxTriangleDown, RxTriangleUp } from "react-icons/rx";

import { motion, AnimatePresence } from "framer-motion";

// import { AdminNavItems } from "./AdminNavItems";
// import { RegularNavItems } from "./RegularNavItems";

import { decryptData } from "../../../util/util";
import { useSelector, useDispatch } from "react-redux";
import { userLogOut } from "../../../store/User";

import logo from "../../../assets/logo.jpeg";
import axios from "axios";
import DynamicIcon from "../icons";

import DropdownMenu from "../dropdownMenu";
import DropdownMobileMenu from "../dropdownMobileMenu";

const baseUrl = window.siteSettings.baseUrl;

function MainNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adminNavList, setAdminNavList] = useState([]);
  const [navList, setNavList] = useState([]);

  const user = useSelector((state) => state.reducer.CurrentUser.currentUser);
  const userRole = useSelector((state) => state.reducer.CurrentUser.role);
  const loggedIn = useSelector((state) => state.reducer.CurrentUser.loggedIn);
  const company = useSelector((state) => state.reducer.companySettings.list);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [navBarItems, setNavBarItems] = useState([]);
  const [allMenuItems, setAllMenuItems] = useState([]);

  const [role, setRole] = useState("user");
  const [logoUrl, setLogoUrl] = useState(logo);

  //  const routePath = router.pathname;
  const [showUserInfoPopup, setShowUserInfoPopup] = useState(false);
  const mobileMenuList = useRef();

  const userPopupRef = useRef();

  const logout = () => {
    dispatch(userLogOut());
    window.location.replace("/login");
  };

  const closeMobileMenu = () => {
    setShowMobileMenu(false);
  };

  const openMobileMenu = () => {
    setShowMobileMenu(true);
  };

  function closeUserPopup() {
    setShowUserInfoPopup(false);
  }
  const showHideUserPopup = (event) => {
    event.stopPropagation();
    setShowUserInfoPopup(!showUserInfoPopup);
  };

  const getNavItems = async () => {
    const body = {
      what: "all",
    };
    try {
      let url = baseUrl + "/api/menu_get.php";
      const res = await axios({
        url: url,
        method: "POST",
        data: JSON.stringify(body),
        header: { "content-type": "application/json" },
      });
      if (res.data.error !== null) {
        if (res.data.error === "true") {
          // setShowAlert(true);
          // setAlertType("danger");
          // setAlertMsg(res.data.message);
        } else {
          // setShowAlert(false);
          // setAlertType(null);
          // setAlertMsg(null);
          setAllMenuItems(res.data);
          const al = res.data.filter(
            (fd) =>
              fd.menu_type.split(",").includes("admin") &&
              parseInt(fd.parent_id) === 0
          );
          const ul = res.data.filter(
            (fd) =>
              fd.menu_type.split(",").includes("regular") &&
              parseInt(fd.parent_id) === 0
          );
          setAdminNavList(al);
          setNavList(ul);

          // console.log(ul);
          // console.log(al);
        }
      }
    } catch (error) {
      // setShowAlert(true);
      // setAlertType("danger");
      // setAlertMsg(
      //   "<h5>Error fetching ad data from server </h5>" +
      //     error +
      //     "<br>Please contact your developer, or try again later"
      // );
    }
  };

  useEffect(() => {
    getNavItems();
    // const handleClickOutside = (event) => {
    //   if (userPopupRef.current && !userPopupRef.current.contains(event.target))
    //    setShowUserInfoPopup(false);
    // };
    // document.addEventListener("click", handleClickOutside, true);
    // return () => {
    //   document.removeEventListener("click", handleClickOutside, true);
    // };

    if (company && company.length > 0) {
      if (company[0].logo_file_name) {
        setLogoUrl(`${baseUrl}/uploads/company/${company[0].logo_file_name}`);
      }
    } else {
      setLogoUrl(logo);
    }
  }, [company]);

  useEffect(() => {
    if (loggedIn && decryptData(userRole) === "admin") {
      setNavBarItems(adminNavList);
      setRole(decryptData(userRole));
    } else {
      setNavBarItems(navList);
      // setRole(decryptData(userRole));
    }
  }, [loggedIn, adminNavList, navList, userRole]);

  const getUserInfo = () => {
    let userImage = null;
    if (loggedIn && user) {
      if (user.photo_filename && user.photo_filename.length > 0) {
        return (
          <img
            alt="member"
            src={`${baseUrl}/uploads/members/${decryptData(
              user.photo_filename
            )}`}
            // className="userProfileImg"
            className="w-[60px] h-[60px]  border-0 rounded-full md:w-[30px] md:h-[30px]"
          />
        );
      } else {
        return (
          <span>
            {decryptData(user.firstname).substring(0, 1) +
              decryptData(user.lastname).substring(0, 1)}
          </span>
        );
      }
    }

    if (userImage.length < 1) {
      return (
        <div className="profileImgCircle">
          {showUserInfoPopup ? (
            <RxTriangleUp className="profileImgArrow" />
          ) : (
            <RxTriangleDown className="profileImgArrow" />
          )}

          {userImage}
        </div>
      );
    } else {
      return "";
    }
    // userImage = decryptData(user.photo_filename);
    // return userImage;
  };

  const getMobileUserInfo = () => {
    if (!loggedIn) {
      return (
        <div className="flex justify-center items-center flex-col mb-3">
          <div className="mobileLogoWrapper">
            <img src={logoUrl} className="mobileLogoImg" alt="peldruk logo" />
          </div>

          <div className="mobileLogoTxt"> PEL DRUK</div>
          <div className="d-flex justify-content-end w-100">
            <div
              className="btn-group"
              role="group"
              aria-label="login and register button"
            >
              <button
                type="button"
                className="btn btn-sm btn-warning"
                onClick={() => {
                  navigate("/login");
                  closeMobileMenu();
                }}
              >
                <div className="flex items-center text-black">
                  <DynamicIcon name={"AiOutlineLogin"} />
                  <div className="pl-[3px]">Login</div>
                </div>
              </button>
              {/* <button type="button" className="btn btn-sm bg-babyblue">
                Signup
              </button> */}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center items-center flex-col mb-3">
          <div
            className=" small text-white cursor-pointer"
            onClick={() => navigate("/admin/profile")}
          >
            <div className="">{getUserInfo()}</div>
          </div>
          <div className="flex justify-end w-[100%]">
            <button
              type="button"
              className="btn btn-sm btn-warning"
              onClick={() => {
                logout();
                closeMobileMenu();
              }}
            >
              Logout
            </button>
          </div>
        </div>
      );
    }
  };

  function hasSubMenu(menuId) {
    const sm = allMenuItems.filter(
      (m) => parseInt(m.parent_id) === parseInt(menuId)
    );
    return sm;
  }

  return (
    <>
      <div
        className="flex w-[100%] justify-between items-end border-b-[5px] border-b-[#a30508] p-0 m-0"
        onClick={closeUserPopup}
      >
        <div className="flex flex-col justify-center item-center mr-5">
          <img
            src={logoUrl}
            className="h-[100px] w-[auto]"
            alt="peldruk logo"
          />
          <div
            className="font-[Alfa Slab One, cursive] text-[22px]"
            // style={{ fontFamily: "Alfa Slab One, cursive", fontSize: 22 }}
          >
            PEL DRUK
          </div>
        </div>

        <nav className="flex justify-center items-center bg-[#6b0001] text-white rounded-tr-[8px] rounded-tl-[8px] max-h-[50px]">
          <div
            type="button"
            className="flex flex-col w-[53px] p-[7px] self-center lg:hidden cursor-pointer"
            onClick={openMobileMenu}
          >
            <span className="h-[3px] mb-1 w-[100%] first-letter:rounded-[10px] bg-yellow-300"></span>
            <span className="h-[3px] mb-1 w-[100%] rounded-[10px] bg-yellow-400"></span>
            <span className="h-[3px] w-[100%] rounded-[10px] bg-yellow-500"></span>
          </div>

          <div className="hidden lg:flex lg:flex-row lg:items-center lg:justify-center relative">
            <ul className="flex items-center justify-center m-0 px-2">
              {navBarItems.map((m, index) => {
                return (
                  <div key={index} className="h-[100%]">
                    {m.access_role.includes(role) ? (
                      hasSubMenu(m.id) && hasSubMenu(m.id).length > 0 ? (
                        <li className="relative px-[7px] hover:bg-[#a30508]">
                          <div className="flex items-center">
                            <DynamicIcon name={m.icon} />
                            <DropdownMenu dropdownLbl={m.title}>
                              <ul className="m-0 p-0">
                                {hasSubMenu(m.id).map((sm, sidx) => (
                                  <li
                                    key={sidx}
                                    className="pl-[8px] py-[7px] hover:bg-[#680406] no-underline text-white h-[100%] cursor-pointer"
                                    onClick={() => navigate(sm.link)}
                                  >
                                    <div className="flex items-center">
                                      <DynamicIcon name={sm.icon} />
                                      <div className="pl-[3px]">{sm.title}</div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </DropdownMenu>
                          </div>
                        </li>
                      ) : (
                        <li
                          key={index}
                          onClick={() => navigate(m.link)}
                          className="relative px-[7px] hover:bg-[#a30508] cursor-pointer h-[50px] flex"
                        >
                          <div className="flex items-center text-white">
                            <DynamicIcon name={m.icon} />
                            <div className="pl-[3px]">{m.title}</div>
                          </div>
                        </li>
                      )
                    ) : null}
                  </div>
                );
              })}
              {!loggedIn && (
                <li
                  className="relative mx-[5px] hover:bg-[#a30508] cursor-pointer h-[50px] flex"
                  onClick={() => navigate("/login")}
                >
                  <div className="flex items-center text-white">
                    <DynamicIcon name={"AiOutlineLogin"} />
                    <div className="pl-[3px]">Login</div>
                  </div>
                </li>
              )}
            </ul>
          </div>
          {loggedIn && (
            <div
              className="hidden lg:flex ml-5 relative"
              onMouseEnter={() => setShowUserInfoPopup(true)}
              onClick={() => setShowUserInfoPopup(!showUserInfoPopup)}
              onMouseLeave={() => setShowUserInfoPopup(false)}
            >
              <div
                className="userProfileMenu"
                style={{ cursor: "pointer" }}
                ref={userPopupRef}
                onClick={(e) => showHideUserPopup(e)}
              >
                <div className="userProfileImgWrapper">{getUserInfo()}</div>
              </div>
              <AnimatePresence>
                {showUserInfoPopup && (
                  <motion.div
                    onMouseEnter={() => setShowUserInfoPopup(true)}
                    key={"menu"}
                    initial={{ y: "-5", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{
                      y: "-50",
                      opacity: 1,
                      transition: { ducation: 0.2, ease: "easeOut" },
                    }}
                    transition={{
                      duration: 0.3,
                      ease: "easeOut",
                    }}
                    className="flex flex-column userProfilePopup"
                  >
                    {loggedIn && user && (
                      <ul>
                        <li>
                          <div
                            className="flex items-center cursor-pointer"
                            onClick={() => navigate("/admin/profile")}
                          >
                            <div className="pr-2">
                              <div className="userProfileImgWrapper">
                                {getUserInfo()}
                              </div>
                            </div>
                            <div>
                              {decryptData(user.firstname)}{" "}
                              {decryptData(user.lastname)} <br />
                              <div className="small">
                                {decryptData(user.email)}
                              </div>
                            </div>
                          </div>
                        </li>
                        {/* <li>Edit Profile</li> */}
                        <li onClick={logout} style={{ cursor: "pointer" }}>
                          Logout
                        </li>
                      </ul>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
        </nav>
      </div>

      {/* MOBILE NAV */}
      <AnimatePresence>
        {showMobileMenu && (
          <div
            className="mobileNavWrapper"
            ref={mobileMenuList}
            // onClick={() => closeMobileMenu()}
          >
            <motion.div
              className="mobileNav"
              style={{ zIndex: 91 }}
              key={"menu"}
              initial={{ x: "-80%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{
                x: "-80%",
                opacity: 1,
                transition: { ducation: 0.2, ease: "easeOut" },
              }}
              transition={{
                duration: 0.3,
                ease: "easeOut",
              }}
            >
              <div
                className="flex text-white cursor-pointer absolute top-1 right-3 text-[20px]"
                onClick={() => closeMobileMenu()}
              >
                x
              </div>

              <div className="m-5">
                {getMobileUserInfo()}
                <ul>
                  {navBarItems.map((m, index) => {
                    return (
                      <div key={index}>
                        {m.access_role.includes(role) ? (
                          hasSubMenu(m.id) && hasSubMenu(m.id).length > 0 ? (
                            <li className="relative">
                              <div className="d-flex align-items-center">
                                <DropdownMobileMenu
                                  dropdownLbl={m.title}
                                  iconName={m.icon}
                                >
                                  {hasSubMenu(m.id).map((sm, sidx) => (
                                    <div key={sidx} className="pb-[10px]">
                                      <Link
                                        onClick={closeMobileMenu}
                                        to={sm.link}
                                        key={sidx}
                                      >
                                        <div className="flex items-center p-0 m-0">
                                          <DynamicIcon
                                            name={sm.icon}
                                            clName="text-white mr-3"
                                          />
                                          <div className="">{sm.title}</div>
                                        </div>
                                      </Link>
                                    </div>
                                  ))}
                                </DropdownMobileMenu>
                              </div>
                            </li>
                          ) : (
                            <li
                              key={index}
                              onClick={closeMobileMenu}

                              // className={router.pathname === m.link ? 'active' : ''}
                            >
                              <Link to={m.link} key={index}>
                                <div className="d-flex align-items-center">
                                  <DynamicIcon
                                    name={m.icon}
                                    clName="text-white mr-3"
                                  />
                                  <div className="menuLabel">{m.title}</div>
                                </div>
                              </Link>
                            </li>
                          )
                        ) : null}
                      </div>
                    );
                  })}

                  {/* {!loggedIn && (
                    <li>
                      <Link to="/login">
                        <div className="d-flex align-items-center">
                          <DynamicIcon
                            name={"AiOutlineLogin"}
                            clName="text-white mr-3"
                          />
                          <div className="menuLabel">Login</div>
                        </div>
                      </Link>
                    </li>
                  )} */}
                </ul>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
}

export default MainNavbar;
