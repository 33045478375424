import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideAlertPopup } from "../../../store/AlertPopup";
import { BiError } from "react-icons/bi";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import "./styles.scss";

export const AlertPopup = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.reducer.AlertPopup);
  const close = () => {
    dispatch(hideAlertPopup());
  };
  useEffect(() => { }, []);
  if (!alert.showAlert) return;
  return (
    <>
      <Modal
        id="alertModal"
        show={alert.showAlert}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        onHide={close}
      >
        <Modal.Body className={"alert alert-" + alert.alertType}>
          {/* <Modal.Header closeButton> */}
          <Modal.Header>
            <Modal.Title>
              <div className="d-flex align-items-center ">
                {alert.alertType === "danger" && (
                  <BiError className="text-danger me-1 pt-n5" size="28" />
                )}
                {alert.alertTitle}
              </div>
            </Modal.Title>
          </Modal.Header>
          <div className="ps-5">{alert.alertMessage}</div>
          <Modal.Footer>
            <Button
              className={"btn btn-sm btn-" + alert.alertType}
              onClick={close}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AlertPopup;
