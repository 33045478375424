import React from "react";
import Router from "./router";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import TopbarMsg from "./components/common/TopbarMsg";
function App() {
  return (
    <>
      <TopbarMsg />
      <BrowserRouter>
        <div className="container">
          {/* <Header /> */}
          <Router />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
