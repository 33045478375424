import * as AI from "react-icons/ai";
import * as BS from 'react-icons/bs';
import * as FA from 'react-icons/fa';
import { iconNames } from "./iconnames";



const DynamicIcon = ({
    name,
    // size="25"
    // color="black"
    clName = "" }) => {
    let IconComponent;
    try {

        if (!name) {
            // Return a default one
            return "";
        }
        const iconExists = iconNames.includes(name);
        if (iconExists) {
            IconComponent = BS[name];

            const iconType = name.slice(0, 2);

            if (iconType.toLowerCase() === "bs") {
                IconComponent = BS[name];
            } else if (iconType.toLowerCase() === "ai") {
                IconComponent = AI[name];
            } else {
                IconComponent = FA[name];
            }
            return <IconComponent className={clName} />;
        }
        else {

            return <div className="text-red-700">Icon not found. choose another icon</div>;
        }




    } catch (error) {
        return "";
    }
};

export default DynamicIcon;