import React from "react";
import loadable from "@loadable/component";
import { Watch } from "react-loader-spinner";

import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import AlertPopup from "../components/common/AlertPopup/";

import Header from "../components/common/header";

import Contact from "../frontend/contact";
import About from "../frontend/about";
import NotFound from "../frontend/notFound";

//ADMIN
const Loading = (props) => {
  if (props.error) {
    return (
      <div className="flex justify-center items-center p-5 mt-5">
        {" "}
        Error Occurred!
      </div>
    );
  } else {
    return (
      <div className="flex justify-center items-center p-5 mt-5">
        <div className="flex flex-col justify-start items-center">
          <Watch
            height="80"
            width="80"
            radius="48"
            color="#4fa94d"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
          <div className="text-[18px] text-green-800 mt-2">...Loading...</div>
        </div>
      </div>
    );
  }
};

const Home = loadable(
  () => import("../frontend/home" /* webpackChunkName: "home" */),
  {
    fallback: <Loading />,
  }
);

/* test only */
const MembersTst = loadable(
  () => import("../frontend/members" /* webpackChunkName: "login" */),
  {
    fallback: <Loading />,
  }
);

const Login = loadable(
  () => import("../frontend/login" /* webpackChunkName: "login" */),
  {
    fallback: <Loading />,
  }
);

const Resetpassword = loadable(
  () =>
    import("../frontend/resetpassword" /* webpackChunkName: "resetpassword" */),
  {
    fallback: <Loading />,
  }
);

const ForceResetpassword = loadable(
  () =>
    import(
      "../frontend/forcepasswordreset" /* webpackChunkName: "forceresetpassword" */
    ),
  {
    fallback: <Loading />,
  }
);
const Forgotpassword = loadable(
  () =>
    import(
      "../frontend/forgotpassword" /* webpackChunkName: "forgotpassword" */
    ),
  {
    fallback: <Loading />,
  }
);
const Contents = loadable(
  () => import("../frontend/page" /* webpackChunkName: "contents" */),
  {
    fallback: <Loading />,
  }
);

//ADMIN
const Dashboard = loadable(
  () => import("../admin/dashboard" /* webpackChunkName: "dashboard" */),
  {
    fallback: <Loading />,
  }
);
const Media = loadable(
  () => import("../admin/media" /* webpackChunkName: "media" */),
  {
    fallback: <Loading />,
  }
);
const Category = loadable(
  () => import("../admin/category" /* webpackChunkName: "category" */),
  {
    fallback: <Loading />,
  }
);
const Members = loadable(
  () => import("../admin/members" /* webpackChunkName: "members" */),
  {
    fallback: <Loading />,
  }
);

const MemberDetail = loadable(
  () =>
    import("../admin/members/MemberDetail" /* webpackChunkName: "members" */),
  {
    fallback: <Loading />,
  }
);

const PushNotification = loadable(
  () =>
    import(
      "../admin/pushnotification" /* webpackChunkName: "pushnotification" */
    ),
  {
    fallback: <Loading />,
  }
);
const SettingsList = loadable(
  () => import("../admin/settings" /* webpackChunkName: "settingslist" */),
  {
    fallback: <Loading />,
  }
);

const CompanySettings = loadable(
  () => import("../admin/company" /* webpackChunkName: "companysettings" */),
  {
    fallback: <Loading />,
  }
);

const Horoscope = loadable(
  () => import("../admin/horoscope" /* webpackChunkName: "horoscope" */),
  {
    fallback: <Loading />,
  }
);

const Menu = loadable(
  () => import("../admin/menu" /* webpackChunkName: "menu" */),
  {
    fallback: <Loading />,
  }
);
const MediaListForCategory = loadable(
  () =>
    import(
      "../admin/category/MediaListForCategory" /* webpackChunkName: "mediaListforcategory" */
    ),
  {
    fallback: <Loading />,
  }
);
const Profile = loadable(
  () => import("../admin/profile" /* webpackChunkName: "profile" */),
  {
    fallback: <Loading />,
  }
);
const Subcategorylist = loadable(
  () =>
    import(
      "../admin/category/Subcategorylist" /* webpackChunkName: "subcategorylist" */
    ),
  {
    fallback: <Loading />,
  }
);

const GroupList = loadable(
  () =>
    import("../admin/media/group" /* webpackChunkName: "MediaSeriesList" */),
  {
    fallback: <Loading />,
  }
);

const NotAuthorized = loadable(
  () =>
    import("../admin/notauthorized" /* webpackChunkName: "notauthorized" */),
  {
    fallback: <Loading />,
  }
);
const PageContents = loadable(
  () => import("../admin/pagecontents" /* webpackChunkName: "pagecontents" */),
  {
    fallback: <Loading />,
  }
);

const Router = ({ props }) => {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <AlertPopup key={2} />

          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot" element={<Forgotpassword />} />
            <Route
              exact
              path="/forcereset/:loggedInEmail/:loggedInPassword/:loggedInName"
              element={<ForceResetpassword />}
            />

            <Route exact path="/reset/:vc/:email" element={<Resetpassword />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/mem" element={<MembersTst />} />

            {/* <Route path="/notify" element={<Notify />} />


              */}

            <Route exact path="/:urlSlug" element={<Contents />} />

            {/* ADMIN ROUTE */}
            <Route path="/admin" element={<Dashboard />} />
            <Route path="/admin/horoscope" element={<Horoscope />} />
            <Route path="/admin/category" element={<Category />} />
            <Route path="/admin/media" element={<Media />} />
            <Route path="/admin/grouplist/:listType" element={<GroupList />} />
            <Route path="/admin/grouplist/:listType" element={<GroupList />} />

            <Route path="/admin/settings" element={<SettingsList />} />
            <Route path="/admin/company" element={<CompanySettings />} />
            <Route path="/admin/menu" element={<Menu />} />
            <Route path="/admin/pages" element={<PageContents />} />

            <Route
              path="/admin/pushnotification"
              element={<PushNotification />}
            />
            <Route path="/admin/members" element={<Members />} />
            <Route path="/admin/members/:memberId" element={<MemberDetail />} />
            <Route
              path="/admin/category/:categoryId"
              element={<Subcategorylist />}
            />
            <Route
              path="/admin/medialist/:categoryId"
              element={<MediaListForCategory />}
            />
            <Route path="/admin/profile" element={<Profile />} />
            {/*  <Route path="/admin/company" element={<Company />} /> */}

            <Route path="/notauthorized" element={<NotAuthorized />} />
            <Route exact path="/error" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </>
    </HelmetProvider>
  );
};

export default Router;
